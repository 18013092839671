<template>
  <div>
    <Header />

    <div class="working">
      <div class="working-top breadcrumbs d-flex align-items-center">
        <div
          class="container position-relative d-flex flex-column align-items-center"
        >
          <h2>{{ $t("how_working.working_title") }}</h2>
        </div>
      </div>
    </div>
    <b-container>
      <b-row class="working-block1-img">
        <b-col sm="12" md="6" class="working-block1-col">
          <div class="working-block1-info">
            <h1>{{ $t("how_working.working_main") }}</h1>
            <div v-for="(step, index) in steps" :key="`step${index}`">
              <div class="working-points">
                <h3
                  @mouseover="
                    () => {
                      changeStep(index);
                    }
                  "
                >
                  {{ index + 1 + `.` }}
                  {{ $t("how_working.working_label" + (index + 1)) }}
                </h3>
                <div class="working-detail" v-show="currentStep == index">
                  {{ $t("how_working.working_p" + (index + 1)) }}
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <img
            class="blockchainImg"
            :src="require(`@/assets/images/info/${steps[currentStep].image}`)"
            alt=""
            width="100"
          />
        </b-col>
      </b-row>
      <b-row class="working-block1-img">
        <div
          class="container position-relative d-flex flex-column align-items-center mb-2"
        >
          <h1 class="text-lg">{{ $t("how_working.working_why_title") }}</h1>
        </div>
        <div class="col-md-3" v-for="(card, index) in cards" :key="index">
          <div class="card">
            <div class="card-head align-items-center text-center mt-1">
              <img
                :src="require(`@/assets/images/info/${card.image}`)"
                :alt="card.alt"
                width="150"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                {{ $t("how_working.working_why_title" + (index + 1)) }}
              </h5>
              <p class="card-text">
                {{ $t("how_working.working_why_detail" + (index + 1)) }}
              </p>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BContainer,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormDatepicker,
  BFormTextarea,
  BMedia,
  BImg,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BContainer,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BMedia,
    BImg,
    BCardText,
  },

  data() {
    return {
      name: "",
      email: "",
      message: "",
      showDiv: false,
      currentStep: 0,
      currentImage: "aboutUs.png",
      cards: [
        {
          image: "visibility.png",
          title: "Card 1",
          description: "This is a description for card 1",
          alt: "Visibility Image",
        },
        {
          image: "accesibility.png",
          title: "Card 2",
          description: "This is a description for card 2",
          alt: "Accessibility Image",
        },
        {
          image: "247_support.png",
          title: "Card 3",
          description: "This is a description for card 3",
          alt: "24/7 Support Image",
        },
        {
          image: "security.png",
          title: "Card 4",
          description: "This is a description for card 4",
          alt: "Security Image",
        },
      ],
      steps: [
        {
          image: "register.png",
          title: "Title 1",
        },
        {
          image: "create_profile.png",
          title: "Title 2",
        },
        {
          image: "kyc.png",
          title: "Title 3",
        },
        {
          image: "nft.png",
          title: "Title 4",
        },
        {
          image: "startbiz.png",
          title: "Title 5",
        },
      ],
    };
  },
  methods: {
    changeStep(text) {
      this.currentStep = text;
    },
  },
};
</script>

<style scoped>
.text-lg {
  font-size: 48px;
}
.working {
  /* margin-top: 110px; */
  margin-bottom: 75px;

  /* padding: 40px 40px 128px 40px; */
}
.working-block1-col {
  margin-top: 50px;
}

.working .contact-top {
  height: 290px;
}
.card-body {
  min-height: 0px !important;
}
.working .working-block1-img img {
  height: 300px;
}
.working-block1-img {
  margin-bottom: 60px;
}
.working .working-block1-info {
  margin-top: 50px;
  padding-left: 50px;
}
.working .working-block1-info h2 {
  font-size: 25px;
  color: #005692;
}
.working .working-block1-info p {
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
}

@media (max-width: 550px) {
  .working .working-top img {
    width: 330px;
  }
  .working .working-block1-img img {
    display: none;
  }
  .working .working-block1-info p {
    text-align: start;
  }
}

.working-block2 {
  margin-top: 60px;
}

.working-points {
  padding: 20px;
  padding-bottom: 0px;
}
.working-detail {
  padding-top: 10px;
  padding-left: 40px;
  font-size: large;
  /* display: none; */
}
.working-block2 h2 {
  text-align: center;
  font-weight: 500;
  color: #005692;
  margin-bottom: 45px;
}

[dir] .card-img,
[dir] .card-img-top {
  height: 250px;
}
[dir] .card .card-title {
  font-weight: 500;
  cursor: pointer;
}
[dir] .card-text:last-child {
  font-size: 14px;
}

@media (max-width: 550px) {
  .block-cvshot .card {
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    align-content: flex-start;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .block-cvshot img {
    margin: 0 7px;
    width: 350px;
  }
}
@media (min-width: 920px) {
  .block-cvshot .card .card-title {
    color: #366ce3;
    font-weight: 600;
    font-size: 22px;
  }
  .block-cvshot .card-text:last-child {
    font-size: 15px;
  }
}
@media (min-width: 920px) {
  [dir="ltr"] .card-body {
    padding: 1.5rem;
    min-height: 350px;
  }
  .cvshots-card .card-body {
    min-height: auto;
    margin: auto;
  }
}
.card-text:last-child {
  font-size: 16px;
  font-weight: 500;
}

.working-top {
  background-image: url(../../../assets/images/info/about-header.jpg);
  padding: 140px 0 60px 0;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs:before {
  content: "";
  background-color: rgba(27, 47, 69, 0.7);
  position: absolute;
  inset: 0;
}
.breadcrumbs h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.working-block1-info ul {
  list-style: none;
  padding: 0;
}

.working-block1-info ul li {
  padding: 0 0 15px 26px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
}
.working-block1-info ul li i {
  font-size: 16px;
  color: #56b8e6;
}
.blockchainImg {
  width: 450px;
}
@media (max-width: 550px) {
  .blockchainImg {
    display: none;
    /* width: 400px; */
  }
  .working-block2 h2 {
    font-size: 18px;
  }
}
</style>
<style scoped>
@media (min-width: 920px) {
  [dir="rtl"] .card-body {
    padding: 1.5rem;
    min-height: 250px;
  }
}
</style>
